$roboto-font-path: "../node_modules/materialize-css/dist/fonts/roboto/";
$fa-font-path: "../node_modules/font-awesome/fonts";

/*
 * Materialize
 */
$primary-color: #233140;
$secondary-color: #0275d8;

$button-raised-background: $primary-color;
$button-raised-background-hover: lighten($button-raised-background, 5%);
$button-floating-background: $primary-color;
$button-floating-background-hover: lighten($button-raised-background, 5%);

$placeholder-text-color: grey;
$radio-fill-color: $primary-color;
$switch-bg-color: $primary-color;
$switch-checked-lever-bg: desaturate(lighten($primary-color, 25%), 25%);

$h1-fontsize: 2.5rem;
$h2-fontsize: 2rem;
$h3-fontsize: 1.75rem;
$h4-fontsize: 1.5rem;
$h5-fontsize: 1.25rem;
$h6-fontsize: 1rem;

/*
 * Bootstrap
 */
$body-bg: #F5F5F5;
$font-family-sans-serif: Roboto, "Helvetica Neue", Arial, sans-serif;
//$font-family-sans-serif: Quicksand,sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
//$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

/**
 * Custom
 */

$btn-cancel-color: #e4e4e4;

$light-grey: #f7f7f9;
$darken-grey: rgba(17, 17, 17, 0.6);
$lighten-grey: rgba(17, 17, 17, 0.1);
$w-pvw-card-header-bg-color: $light-grey;

$h1-fontsize-sm-down: 2.25rem;

/**
 * Notification
 */
$notification-success-bg-color: #DFF2BF;
$notification-success-font-color: #4F8A10;

$notification-warning-bg-color: #FEEFB3;
$notification-warning-font-color: #9F6000;

$notification-error-bg-color: #FFBABA;
$notification-error-font-color: #D8000C;

$notification-info-bg-color: #BDE5F8;
$notification-info-font-color: #00529B;

/**
 * Toast
 */
$toast-success-bg-color: rgba(0%, 60%, 0%, .7);
$toast-warning-bg-color: rgba(220, 167, 0, 0.9);
$toast-error-bg-color: rgba(100%, 0%, 0%, .9);
