@import "variables";
@import "../../../node_modules/bootstrap/scss/bootstrap";

@include media-breakpoint-down(sm) {

  .locale-img {
    width: 90px !important;
    height: 90px !important;
  }

  .container {
    width: 99% !important;
  }

  .promotion {
    padding: unset !important;
  }

  h1 {
    font-size: $h1-fontsize-sm-down;
  }

  .modal-header {
    h1 {
      font-size: 2rem;
    }
  }

  .section-header {
    span {
      font-size: 1.5rem !important;
    }
  }

  .modal {

    &#locale-modal {
      height: 60vh !important;

      .modal-content {
        height: 42vh !important;
      }
    }

    &.share-modal {
      height: 80vh !important;
    }

    &.user-phone-displayer {
      height: 15vh !important;
      width: 80% !important;
    }
  }

  .w-search {
    .service-chooser-box {
      border-bottom: 1px solid #a7a7a7 !important;
      border-right: none !important;
      border-radius: 0;
    }
  }

  .home-title {
    font-size: 2rem;
  }

  .home-banner {
    min-height: initial !important;

    .main-container {
      padding-top: 5vh !important;
    }
  }

  .up-pers-info, #user-complete {
    .profile-picture {
      height: 100px !important;
      width: 100px !important;
    }
  }

  .search-sh {
    .sbox {
      padding: 0.8rem 0 !important;
    }

    .sbox-submit {
      padding: 1rem 0 !important;
    }
  }

  #profile-activity-chooser {
    .modal-content {

      .activity-chooser {

        .yf-categories {
          overflow-y: auto;
          height: 60vh;
        }

        .yf-services {
          overflow-y: auto;
          height: 60vh;
        }
      }
    }
  }

}

@include media-breakpoint-down(md) {

  .worker-picture {
    width: 10rem;
    height: 10rem;
  }

  #navbar-notices, #navbar-sm-notices {

    .notices-body {
      width: 90vw !important;
    }
  }

}

@media (min-width: 1150px) and (max-width: 1400px) {
  .container {
    width: 90% !important;
  }
}

@include media-breakpoint-down(md) {
  .ads-ctr-left, .ads-ctr-right {
    display: none;
  }
}

@include media-breakpoint-up(md) {

  .rtl {
    .float-md-left {
      float: right !important;
    }
  }

  .locale-img {
    width: 70px !important;
    height: 70px !important;
  }

  #activity-chooser {

    .modal-content {

      .activity-chooser {

        .yf-categories {
          overflow-y: auto;
          height: 75vh;
        }

        .yf-services {
          overflow-y: auto;
          height: 75vh;
        }
      }
    }
  }

  .modal {

    &#locale-modal {
      height: 50vh !important;

      .modal-content {
        height: 42vh !important;
      }
    }

    &.user-phone-displayer {
      height: 15vh !important;
      width: 30vw !important;
    }
  }

  #profile-activity-chooser {
    .modal-content {

      .activity-chooser {

        .yf-categories {
          overflow-y: auto;
          height: 65vh;
        }

        .yf-services {
          overflow-y: auto;
          height: 65vh;
        }
      }
    }
  }
}

@include media-breakpoint-between(sm, lg) {
  .container {
    width: 90% !important;
  }
}

@include media-breakpoint-between(lg, xl) {
  .home-title {
    font-size: 4rem;
  }

  .ads-ctr-left {
    width: 15%;
    position: absolute;
    left: 10px;
  }

  .ads-ctr-right {
    width: 15%;
    position: absolute;
    right: 10px;
  }

}