.rtl {
  direction: rtl;

  .close-modal-action {
    right: unset;
    left: 10px;
  }

  .iti--allow-dropdown {

    .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
      left: auto;
      right: 0;
    }

    .iti__selected-flag {
      .iti__arrow {
        margin-right: 6px !important;
      }
    }

    input[type=text] {
      padding-left: 6px !important;
      padding-right: 52px !important;
      margin-right: 0;
    }

    ul.iti__country-list {
      direction: ltr;
    }
  }

  label {
    &.select-all-label {
      padding-left: 30px !important;
    }
  }

  .input-error {
    .invalid {
      text-align: right;
    }
  }

  #dropdown-sort {
    left: unset !important;;
  }

  .notification-close {
    left: 5px;
    right: unset !important;
  }

  .right {
    float: left !important;
  }

  [type="radio"] + label:before, [type="radio"] + label:after {
    right: 0;
    left: unset;
  }

  [type="radio"]:not(:checked) + label, [type="radio"]:checked + label {
    padding-right: 30px;
  }

  [type="checkbox"] + label {
    padding-right: 35px;
    padding-left: unset
  }

  [type="checkbox"].filled-in + label:before, [type="checkbox"].filled-in + label:after {
    right: 0;
  }

  [type="checkbox"].filled-in:checked + label:before {
    right: 10px;
  }

  .card .card-header i {
    margin-left: 1rem;
    margin-right: unset;
  }

  .select-wrapper span.caret {
    left: 0;
    right: unset;
  }

  .dropdown-content li {

    text-align: right;

    a, &.a-link:not([href]):not([tabindex]) {

      i {
        float: right;
        margin: unset;
      }

    }

  }

  .collapsible-header {
    i {
      margin-left: 1rem;
    }
  }

  .main-navbar {
    li {
      display: inline-block;
      float: inherit;
    }
  }

  #navbar-user-info {
    li {
      display: block;
    }

    a {
      i {
        margin-left: 15px;
      }
    }
  }

  .list-mode {
    &.rb {
      border-left: 1px solid #D9D9D9;
      border-right: unset !important;
    }
  }

  ul {
    direction: rtl;
  }

  .mr-5 {
    margin-left: 3rem;
    margin-right: unset;
  }

  .right-align {
    text-align: left !important;
  }

  .input-field label {
    right: 0;
    left: unset;
  }

  .input-field label:not(.label-icon).active {
    transform-origin: right top;
  }

  .character-counter {
    float: left !important;
  }

  @each $bp in map-keys($grid-breakpoints) {
    .right-#{$bp}-up {
      @include media-breakpoint-up($bp) {
        float: left !important;
      }
    }
  }

  /**
  Bootstrap RTL
   */

  @media (min-width: 1200px) {
    .float-xl-left {
      float: right !important;
    }
    .float-xl-right {
      float: left !important;
    }
  }

  .mr-0 {
    margin-right: inherit !important;
    margin-left: 0 !important;
  }

  .ml-0 {
    margin-left: inherit !important;
    margin-right: 0 !important;
  }

  .mr-1 {
    margin-right: inherit !important;
    margin-left: 4px !important;
    margin-left: 0.25rem !important;
  }

  .ml-1 {
    margin-left: inherit !important;
    margin-right: 4px !important;
    margin-right: 0.25rem !important;
  }

  .mr-2 {
    margin-right: inherit !important;
    margin-left: 8px !important;
    margin-left: 0.5rem !important;
  }

  .ml-2 {
    margin-left: inherit !important;
    margin-right: 8px !important;
    margin-right: 0.5rem !important;
  }

  .mr-3 {
    margin-right: inherit !important;
    margin-left: 16px !important;
    margin-left: 1rem !important;
  }

  .ml-3 {
    margin-left: inherit !important;
    margin-right: 16px !important;
    margin-right: 1rem !important;
  }

  .mr-4 {
    margin-right: inherit !important;
    margin-left: 24px !important;
    margin-left: 1.5rem !important;
  }

  .ml-4 {
    margin-left: inherit !important;
    margin-right: 24px !important;
    margin-right: 1.5rem !important;
  }

  .mr-5 {
    margin-right: inherit !important;
    margin-left: 48px !important;
    margin-left: 3rem !important;
  }

  .ml-5 {
    margin-left: inherit !important;
    margin-right: 48px !important;
    margin-right: 3rem !important;
  }

  .pr-0 {
    padding-right: inherit !important;
    padding-left: 0 !important;
  }

  .pl-0 {
    padding-left: inherit !important;
    padding-right: 0 !important;
  }

  .pr-1 {
    padding-right: inherit !important;
    padding-left: 4px !important;
    padding-left: 0.25rem !important;
  }

  .pl-1 {
    padding-left: inherit !important;
    padding-right: 4px !important;
    padding-right: 0.25rem !important;
  }

  .pr-2 {
    padding-right: inherit !important;
    padding-left: 8px !important;
    padding-left: 0.5rem !important;
  }

  .pl-2 {
    padding-left: inherit !important;
    padding-right: 8px !important;
    padding-right: 0.5rem !important;
  }

  .pr-3 {
    padding-right: inherit !important;
    padding-left: 16px !important;
    padding-left: 1rem !important;
  }

  .pl-3 {
    padding-left: inherit !important;
    padding-right: 16px !important;
    padding-right: 1rem !important;
  }

  .pr-4 {
    padding-right: inherit !important;
    padding-left: 24px !important;
    padding-left: 1.5rem !important;
  }

  .pl-4 {
    padding-left: inherit !important;
    padding-right: 24px !important;
    padding-right: 1.5rem !important;
  }

  .pr-5 {
    padding-right: inherit !important;
    padding-left: 48px !important;
    padding-left: 3rem !important;
  }

  .pl-5 {
    padding-left: inherit !important;
    padding-right: 48px !important;
    padding-right: 3rem !important;
  }

  .mr-auto {
    margin-right: inherit !important;
    margin-left: auto !important;
  }

  .ml-auto {
    margin-left: inherit !important;
    margin-right: auto !important;
  }

  @media (min-width: 576px) {
    .mr-sm-0 {
      margin-right: inherit !important;
      margin-left: 0 !important;
    }
    .ml-sm-0 {
      margin-left: inherit !important;
      margin-right: 0 !important;
    }
    .mr-sm-1 {
      margin-right: inherit !important;
      margin-left: 0.25rem !important;
    }
    .ml-sm-1 {
      margin-left: inherit !important;
      margin-right: 0.25rem !important;
    }
    .mr-sm-2 {
      margin-right: inherit !important;
      margin-left: 0.5rem !important;
    }
    .ml-sm-2 {
      margin-left: inherit !important;
      margin-right: 0.5rem !important;
    }
    .mr-sm-3 {
      margin-right: inherit !important;
      margin-left: 1rem !important;
    }
    .ml-sm-3 {
      margin-left: inherit !important;
      margin-right: 1rem !important;
    }
    .mr-sm-4 {
      margin-right: inherit !important;
      margin-left: 1.5rem !important;
    }
    .ml-sm-4 {
      margin-left: inherit !important;
      margin-right: 1.5rem !important;
    }
    .mr-sm-5 {
      margin-right: inherit !important;
      margin-left: 3rem !important;
    }
    .ml-sm-5 {
      margin-left: inherit !important;
      margin-right: 3rem !important;
    }
    .pr-sm-0 {
      padding-right: inherit !important;
      padding-left: 0 !important;
    }
    .pl-sm-0 {
      padding-left: inherit !important;
      padding-right: 0 !important;
    }
    .pr-sm-1 {
      padding-right: inherit !important;
      padding-left: 0.25rem !important;
    }
    .pl-sm-1 {
      padding-left: inherit !important;
      padding-right: 0.25rem !important;
    }
    .pr-sm-2 {
      padding-right: inherit !important;
      padding-left: 0.5rem !important;
    }
    .pl-sm-2 {
      padding-left: inherit !important;
      padding-right: 0.5rem !important;
    }
    .pr-sm-3 {
      padding-right: inherit !important;
      padding-left: 1rem !important;
    }
    .pl-sm-3 {
      padding-left: inherit !important;
      padding-right: 1rem !important;
    }
    .pr-sm-4 {
      padding-right: inherit !important;
      padding-left: 1.5rem !important;
    }
    .pl-sm-4 {
      padding-left: inherit !important;
      padding-right: 1.5rem !important;
    }
    .pr-sm-5 {
      padding-right: inherit !important;
      padding-left: 3rem !important;
    }
    .pl-sm-5 {
      padding-left: inherit !important;
      padding-right: 3rem !important;
    }
    .mr-sm-auto {
      margin-right: inherit !important;
      margin-left: auto !important;
    }
    .ml-sm-auto {
      margin-left: inherit !important;
      margin-right: auto !important;
    }
  }
  @media (min-width: 768px) {
    .mr-md-0 {
      margin-right: inherit !important;
      margin-left: 0 !important;
    }
    .ml-md-0 {
      margin-left: inherit !important;
      margin-right: 0 !important;
    }
    .mr-md-1 {
      margin-right: inherit !important;
      margin-left: 0.25rem !important;
    }
    .ml-md-1 {
      margin-left: inherit !important;
      margin-right: 0.25rem !important;
    }
    .mr-md-2 {
      margin-right: inherit !important;
      margin-left: 0.5rem !important;
    }
    .ml-md-2 {
      margin-left: inherit !important;
      margin-right: 0.5rem !important;
    }
    .mr-md-3 {
      margin-right: inherit !important;
      margin-left: 1rem !important;
    }
    .ml-md-3 {
      margin-left: inherit !important;
      margin-right: 1rem !important;
    }
    .mr-md-4 {
      margin-right: inherit !important;
      margin-left: 1.5rem !important;
    }
    .ml-md-4 {
      margin-left: inherit !important;
      margin-right: 1.5rem !important;
    }
    .mr-md-5 {
      margin-right: inherit !important;
      margin-left: 3rem !important;
    }
    .ml-md-5 {
      margin-left: inherit !important;
      margin-right: 3rem !important;
    }
    .pr-md-0 {
      padding-right: inherit !important;
      padding-left: 0 !important;
    }
    .pl-md-0 {
      padding-left: inherit !important;
      padding-right: 0 !important;
    }
    .pr-md-1 {
      padding-right: inherit !important;
      padding-left: 0.25rem !important;
    }
    .pl-md-1 {
      padding-left: inherit !important;
      padding-right: 0.25rem !important;
    }
    .pr-md-2 {
      padding-right: inherit !important;
      padding-left: 0.5rem !important;
    }
    .pl-md-2 {
      padding-left: inherit !important;
      padding-right: 0.5rem !important;
    }
    .pr-md-3 {
      padding-right: inherit !important;
      padding-left: 1rem !important;
    }
    .pl-md-3 {
      padding-left: inherit !important;
      padding-right: 1rem !important;
    }
    .pr-md-4 {
      padding-right: inherit !important;
      padding-left: 1.5rem !important;
    }
    .pl-md-4 {
      padding-left: inherit !important;
      padding-right: 1.5rem !important;
    }
    .pr-md-5 {
      padding-right: inherit !important;
      padding-left: 3rem !important;
    }
    .pl-md-5 {
      padding-left: inherit !important;
      padding-right: 3rem !important;
    }
    .mr-md-auto {
      margin-right: inherit !important;
      margin-left: auto !important;
    }
    .ml-md-auto {
      margin-left: inherit !important;
      margin-right: auto !important;
    }
  }

  @media (min-width: 992px) {
    .mr-lg-0 {
      margin-right: inherit !important;
      margin-left: 0 !important;
    }
    .ml-lg-0 {
      margin-left: inherit !important;
      margin-right: 0 !important;
    }
    .mr-lg-1 {
      margin-right: inherit !important;
      margin-left: 0.25rem !important;
    }
    .ml-lg-1 {
      margin-left: inherit !important;
      margin-right: 0.25rem !important;
    }
    .mr-lg-2 {
      margin-right: inherit !important;
      margin-left: 0.5rem !important;
    }
    .ml-lg-2 {
      margin-left: inherit !important;
      margin-right: 0.5rem !important;
    }
    .mr-lg-3 {
      margin-right: inherit !important;
      margin-left: 1rem !important;
    }
    .ml-lg-3 {
      margin-left: inherit !important;
      margin-right: 1rem !important;
    }
    .mr-lg-4 {
      margin-right: inherit !important;
      margin-left: 1.5rem !important;
    }
    .ml-lg-4 {
      margin-left: inherit !important;
      margin-right: 1.5rem !important;
    }
    .mr-lg-5 {
      margin-right: inherit !important;
      margin-left: 3rem !important;
    }
    .ml-lg-5 {
      margin-left: inherit !important;
      margin-right: 3rem !important;
    }
    .pr-lg-0 {
      padding-right: inherit !important;
      padding-left: 0 !important;
    }
    .pl-lg-0 {
      padding-left: inherit !important;
      padding-right: 0 !important;
    }
    .pr-lg-1 {
      padding-right: inherit !important;
      padding-left: 0.25rem !important;
    }
    .pl-lg-1 {
      padding-left: inherit !important;
      padding-right: 0.25rem !important;
    }
    .pr-lg-2 {
      padding-right: inherit !important;
      padding-left: 0.5rem !important;
    }
    .pl-lg-2 {
      padding-left: inherit !important;
      padding-right: 0.5rem !important;
    }
    .pr-lg-3 {
      padding-right: inherit !important;
      padding-left: 1rem !important;
    }
    .pl-lg-3 {
      padding-left: inherit !important;
      padding-right: 1rem !important;
    }
    .pr-lg-4 {
      padding-right: inherit !important;
      padding-left: 1.5rem !important;
    }
    .pl-lg-4 {
      padding-left: inherit !important;
      padding-right: 1.5rem !important;
    }
    .pr-lg-5 {
      padding-right: inherit !important;
      padding-left: 3rem !important;
    }
    .pl-lg-5 {
      padding-left: inherit !important;
      padding-right: 3rem !important;
    }
    .mr-lg-auto {
      margin-right: inherit !important;
      margin-left: auto !important;
    }
    .ml-lg-auto {
      margin-left: inherit !important;
      margin-right: auto !important;
    }
  }

  @media (min-width: 1200px) {
    .mr-xl-0 {
      margin-right: inherit !important;
      margin-left: 0 !important;
    }
    .ml-xl-0 {
      margin-left: inherit !important;
      margin-right: 0 !important;
    }
    .mr-xl-1 {
      margin-right: inherit !important;
      margin-left: 0.25rem !important;
    }
    .ml-xl-1 {
      margin-left: inherit !important;
      margin-right: 0.25rem !important;
    }
    .mr-xl-2 {
      margin-right: inherit !important;
      margin-left: 0.5rem !important;
    }
    .ml-xl-2 {
      margin-left: inherit !important;
      margin-right: 0.5rem !important;
    }
    .mr-xl-3 {
      margin-right: inherit !important;
      margin-left: 1rem !important;
    }
    .ml-xl-3 {
      margin-left: inherit !important;
      margin-right: 1rem !important;
    }
    .mr-xl-4 {
      margin-right: inherit !important;
      margin-left: 1.5rem !important;
    }
    .ml-xl-4 {
      margin-left: inherit !important;
      margin-right: 1.5rem !important;
    }
    .mr-xl-5 {
      margin-right: inherit !important;
      margin-left: 3rem !important;
    }
    .ml-xl-5 {
      margin-left: inherit !important;
      margin-right: 3rem !important;
    }
    .pr-xl-0 {
      padding-right: inherit !important;
      padding-left: 0 !important;
    }
    .pl-xl-0 {
      padding-left: inherit !important;
      padding-right: 0 !important;
    }
    .pr-xl-1 {
      padding-right: inherit !important;
      padding-left: 0.25rem !important;
    }
    .pl-xl-1 {
      padding-left: inherit !important;
      padding-right: 0.25rem !important;
    }
    .pr-xl-2 {
      padding-right: inherit !important;
      padding-left: 0.5rem !important;
    }
    .pl-xl-2 {
      padding-left: inherit !important;
      padding-right: 0.5rem !important;
    }
    .pr-xl-3 {
      padding-right: inherit !important;
      padding-left: 1rem !important;
    }
    .pl-xl-3 {
      padding-left: inherit !important;
      padding-right: 1rem !important;
    }
    .pr-xl-4 {
      padding-right: inherit !important;
      padding-left: 1.5rem !important;
    }
    .pl-xl-4 {
      padding-left: inherit !important;
      padding-right: 1.5rem !important;
    }
    .pr-xl-5 {
      padding-right: inherit !important;
      padding-left: 3rem !important;
    }
    .pl-xl-5 {
      padding-left: inherit !important;
      padding-right: 3rem !important;
    }
    .mr-xl-auto {
      margin-right: inherit !important;
      margin-left: auto !important;
    }
    .ml-xl-auto {
      margin-left: inherit !important;
      margin-right: auto !important;
    }
  }

  .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    float: right;
  }

  .pull-xs-0 {
    left: auto;
  }

  @for $i from 1 through 12 {
    .pull-xs-#{$i} {
      left: ($i / 12) * 100 + 0%;
    }
  }

  .push-xs-0 {
    right: auto;
  }

  @for $i from 1 through 12 {
    .push-xs-#{$i} {
      right: ($i / 12) * 100 + 0%;
    }
  }

  @for $i from 0 through 12 {
    .offset-xs-#{$i} {
      margin-right: ($i / 12) * 100 + 0%;
    }
  }

  @media (min-width: 544px) {
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
      float: right;
    }

    .pull-sm-0 {
      left: auto;
    }

    @for $i from 1 through 12 {
      .pull-sm-#{$i} {
        left: ($i / 12) * 100 + 0%;
      }
    }

    .push-sm-0 {
      right: auto;
    }

    @for $i from 1 through 12 {
      .push-sm-#{$i} {
        right: ($i / 12) * 100 + 0%;
      }
    }

    @for $i from 0 through 12 {
      .offset-sm-#{$i} {
        margin-right: ($i / 12) * 100 + 0%;
      }
    }
  }
  @media (min-width: 768px) {
    .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
      float: right;
    }

    .pull-md-0 {
      left: auto;
    }

    @for $i from 1 through 12 {
      .pull-md-#{$i} {
        left: ($i / 12) * 100 + 0%;
      }
    }

    .push-md-0 {
      right: auto;
    }

    @for $i from 1 through 12 {
      .push-md-#{$i} {
        right: ($i / 12) * 100 + 0%;
      }
    }

    @for $i from 0 through 12 {
      .offset-md-#{$i} {
        margin-right: ($i / 12) * 100 + 0%;
      }
    }
  }
  @media (min-width: 992px) {
    .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
      float: right;
    }

    .pull-lg-0 {
      left: auto;
    }

    @for $i from 1 through 12 {
      .pull-lg-#{$i} {
        left: ($i / 12) * 100 + 0%;
      }
    }

    .push-lg-0 {
      right: auto;
    }

    @for $i from 1 through 12 {
      .push-lg-#{$i} {
        right: ($i / 12) * 100 + 0%;
      }
    }

    @for $i from 0 through 12 {
      .offset-lg-#{$i} {
        margin-right: ($i / 12) * 100 + 0%;
      }
    }
  }
  @media (min-width: 1200px) {
    .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
      float: right;
    }

    .pull-xl-0 {
      left: auto;
    }

    @for $i from 1 through 12 {
      .pull-xl-#{$i} {
        left: ($i / 12) * 100 + 0%;
      }
    }

    .push-xl-0 {
      right: auto;
    }

    @for $i from 1 through 12 {
      .push-xl-#{$i} {
        right: ($i / 12) * 100 + 0%;
      }
    }

    @for $i from 0 through 12 {
      .offset-xl-#{$i} {
        margin-right: ($i / 12) * 100 + 0%;
      }
    }
  }

  .navbar-brand {
    float: right;
    margin-right: inherit;
    margin-left: 1rem;
  }

  .navbar-nav .nav-item {
    float: right;
  }

  .navbar-nav .nav-item + .nav-item {
    margin-right: 1rem;
    margin-left: inherit;
  }

  th {
    text-align: right;
  }

  .checkbox label {
    padding-right: 1.25rem;
    padding-left: inherit;
  }

  .radio input, .radio-inline, .checkbox input, .checkbox-inline input {
    margin-right: -1.25rem;
    margin-left: inherit;
  }
}