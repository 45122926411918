@import "../../../node_modules/bootstrap/scss/bootstrap";

@each $bp in map-keys($grid-breakpoints) {
  .right-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      float: right !important;
    }
  }

  .w-100-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      width: 100% !important;
    }
  }
  .w-100-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      width: 100% !important;
    }
  }

  .center-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      text-align: center !important;
    }

  }

  .center-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      text-align: center !important;
    }
  }

  .w-100-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      width: 100% !important;
    }
  }
  .w-100-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      width: 100% !important;
    }
  }
}